@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

@font-face {
  /*フォントの名前*/
  font-family: "A-OTF-RyuminPro-ExBold";
  /*フォントファイルへのパス（複数指定の場合、上から順に読み込まれる）*/
  src: url("/font/A-OTF-RyuminPro-ExBold.woff2") format("woff2"), url("/font/A-OTF-RyuminPro-ExBold.woff") format("woff");
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 14px;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

.mincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1000px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media only screen and (max-width: 1000px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media only screen and (max-width: 1000px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.start {
  background: #FFF;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9000;
}

.start-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 9999;
  width: 200px;
}

@media only screen and (max-width: 834px) {
  .start-logo {
    width: 180px;
  }
}

@media only screen and (max-width: 640px) {
  .start-logo {
    top: 48%;
  }
}

.start-logo img {
  width: 100% !important;
  max-width: 100% !important;
}

.start-text {
  position: absolute;
  display: none;
  left: 50%;
  top: 60%;
  transform: translate(-50%, 0);
  z-index: 9999;
  font-size: 28px;
  color: #e50414;
  font-family: "A-OTF-RyuminPro-ExBold";
  width: 100%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .start-text {
    font-size: 22px;
  }
}

@media only screen and (max-width: 640px) {
  .start-text {
    font-size: 16px;
    top: 55% !important;
  }
}

.start-text {
  position: absolute;
  display: none;
  left: 50%;
  top: 60%;
  transform: translate(-50%, 0);
  z-index: 9999;
  font-size: 28px;
  color: #e50414;
  font-family: "A-OTF-RyuminPro-ExBold";
  width: 100%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .start-text {
    font-size: 22px;
  }
}

@media only screen and (max-width: 640px) {
  .start-text {
    font-size: 22px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  position: relative;
}

.header {
  height: 100px;
  line-height: 100px;
  width: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 99;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 834px) {
  .header {
    height: 80px;
  }
}

.header-inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-logo {
  display: block;
  width: 100%;
  max-width: 180px;
}

@media only screen and (max-width: 1100px) {
  .header-logo {
    width: 20%;
  }
}

@media only screen and (max-width: 834px) {
  .header-logo {
    width: 190px;
    height: 80px;
  }
}

.header-logo img {
  width: 100% !important;
  max-width: 100% !important;
  vertical-align: middle;
}

@media only screen and (max-width: 834px) {
  .header-logo img {
    width: 190px !important;
    vertical-align: top;
    padding-top: 7px;
  }
}

.header-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.header-nav {
  margin-right: 20px;
  max-width: 870px;
  width: 100%;
}

@media only screen and (max-width: 1100px) {
  .header-nav {
    margin-right: 10px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .header-nav {
    position: fixed;
    top: 80px;
    margin-right: 0;
    margin-left: 0;
    background-image: linear-gradient(to right, #e50414 0%, #db6e39 100%);
    right: -100vw;
    transition: all .3s ease;
    height: 100vh;
    padding-top: 40px;
    max-width: 100%;
    width: 80%;
  }
}

.header-nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
}

@media only screen and (max-width: 834px) {
  .header-nav ul {
    flex-direction: column;
    height: auto;
  }
}

.header-nav ul li {
  width: 100%;
}

@media only screen and (max-width: 1100px) {
  .header-nav ul li {
    width: auto;
  }
}

@media only screen and (max-width: 834px) {
  .header-nav ul li {
    height: 80px;
  }
}

.header-nav ul li a {
  color: #333;
  font-size: 13px;
  width: 100%;
  transition: all .3s ease;
}

@media only screen and (max-width: 834px) {
  .header-nav ul li a {
    color: #fff;
    font-size: 18px;
    display: inline;
  }
}

.header-nav ul li a img {
  display: none;
  vertical-align: middle;
}

.header-nav ul li a:hover {
  color: #e50414;
}

@media only screen and (max-width: 834px) {
  .header-nav ul li a:hover {
    color: #fff;
  }
}

.header-nav ul li a.img-in img {
  display: inline;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .header-nav ul li a.img-in img {
    display: none;
  }
}

.header-nav.active {
  right: 0;
}

.header-sns {
  display: flex;
}

@media only screen and (max-width: 834px) {
  .header-sns {
    flex-direction: column;
  }
}

.header-sns a {
  padding: 0 10px;
}

@media only screen and (max-width: 834px) {
  .header-sns a {
    display: block;
  }
}

.header-sns a i {
  font-size: 18px;
}

.header-contact {
  display: block;
  height: 100px;
  width: 200px;
  background-image: linear-gradient(to right, #e50414 0%, #db6e39 100%);
  color: #fff !important;
}

@media only screen and (max-width: 834px) {
  .header-contact {
    width: 80px;
    height: 80px;
    margin-right: 80px;
  }
}

.header-contact i {
  margin-left: 10px;
}

.header-contact .mail-icon {
  display: none;
}

@media only screen and (max-width: 834px) {
  .header-contact .mail-icon {
    display: block;
    line-height: 80px;
    margin-left: 0;
    font-size: 22px;
  }
}

@media only screen and (max-width: 834px) {
  .header-contact .mail-text {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  .header-contact .fa-chevron-right {
    display: none;
  }
}

.res-menu {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  background-color: #fff;
  cursor: pointer;
  display: none;
  z-index: 999;
}

@media only screen and (max-width: 834px) {
  .res-menu {
    display: block;
  }
}

.res-menu div {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  height: 1px;
  width: 35%;
  background-image: linear-gradient(to right, #e50414 0%, #db6e39 100%);
}

.res-menu div:nth-of-type(1) {
  transform: translateY(-7px);
  transition: all .3s ease;
}

.res-menu div:nth-of-type(3) {
  transform: translateY(7px);
  transition: all .3s ease;
}

.res-menu.active div:nth-of-type(1) {
  transform: rotate(45deg);
}

.res-menu.active div:nth-of-type(2) {
  opacity: 0;
}

.res-menu.active div:nth-of-type(3) {
  transform: rotate(-45deg);
}

.slick-list,
.slick-track {
  height: 100% !important;
}

.main {
  margin: 100px 0 0 auto;
  position: relative;
  height: 72.5vh;
  width: 85.6777% !important;
}

@media (max-width: 1000px) {
  .main {
    width: 98% !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .main {
    height: 720px;
  }
}

@media only screen and (max-width: 640px) {
  .main {
    height: 560px;
    margin-top: 80px;
  }
}

.main-photo {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.main-photo:before {
  position: absolute;
  content: "";
  background-color: #666;
  opacity: .1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.main-photo img {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.main-photo picture,
.main-photo li {
  height: 100%;
}

.main-text {
  position: absolute;
  color: #fff;
  z-index: 5;
  left: 5%;
  bottom: 80px;
  font-size: 44px;
  font-family: "A-OTF-RyuminPro-ExBold";
  text-align: left;
  line-height: 1.7;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .main-text {
    left: 2%;
  }
}

@media only screen and (max-width: 640px) {
  .main-text {
    font-size: 6.4vw;
    bottom: 40px;
  }
}

.main-text br {
  display: none;
}

@media only screen and (max-width: 834px) {
  .main-text br {
    display: block;
  }
}

.main-text,
.main-text span {
  opacity: 0;
}

#mainArea {
  width: 100%;
  margin-top: 100px;
}

@media only screen and (max-width: 834px) {
  #mainArea {
    margin-top: 80px;
  }
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/home/main-p2.jpg);
  background-position: center;
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #e60012;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: left;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  padding: 200px 0;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2em;
    padding: 160px 0;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    padding: 120px 0;
  }
}

#local-keyvisual h1 span {
  padding: 150px 0;
  display: block;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 span {
    padding: 100px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.pan1 ol {
  display: flex;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
}

.footer {
  padding: 55px 0;
  background-image: linear-gradient(to right, #e50414 0%, #db6e39 100%);
}

@media only screen and (max-width: 640px) {
  .footer {
    padding: 40px 0 20px;
  }
}

.footer-inner {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
}

.footer-nav {
  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid #fff;
}

@media only screen and (max-width: 640px) {
  .footer-nav {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

.footer-nav ul {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 640px) {
  .footer-nav ul {
    flex-direction: column;
  }
}

@media only screen and (max-width: 640px) {
  .footer-nav ul li {
    padding: 15px 0;
  }
}

.footer-nav ul li a {
  color: #fff;
}

.footer #copy {
  color: #fff;
  font-size: 12px;
}

.footer #copy a {
  color: #fff;
}

#page-animate::before {
  content: '';
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  pointer-events: none;
  right: 100%;
  -webkit-transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

#page-animate::after {
  content: '';
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  pointer-events: none;
  left: 100%;
  -webkit-transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

#page-animate.is-slide-in::before {
  right: 0;
}

#page-animate.is-slide::after {
  left: 0;
}

.cont_01 {
  padding: 150px 0;
}

@media only screen and (max-width: 834px) {
  .cont_01 {
    padding: 110px 0;
  }
}

@media only screen and (max-width: 640px) {
  .cont_01 {
    padding: 90px 0;
  }
}

.cont_01-inner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.cont_01-title h2 {
  font-size: 24px;
}

@media only screen and (max-width: 834px) {
  .info {
    width: 95%;
    margin: 0 auto;
  }
}

.info-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 834px) {
  .info-inner {
    flex-direction: column;
  }
}

.info-title {
  width: 20%;
}

@media only screen and (max-width: 834px) {
  .info-title {
    width: 100%;
  }
}

.info-list {
  width: 80%;
}

@media only screen and (max-width: 834px) {
  .info-list {
    width: 100%;
  }
}

@media only screen and (max-width: 834px) {
  .info-list {
    margin-top: 60px;
  }
}

.info-list dl {
  border-bottom: 1px solid #ddd !important;
  padding-bottom: 30px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  .info-list dl {
    flex-direction: column;
    padding-bottom: 20px;
    margin-bottom: 40px;
  }
}

.info-list dl dt {
  background: none !important;
  border: none !important;
  padding-top: 0 !important;
}

@media only screen and (max-width: 640px) {
  .info-list dl dt {
    width: 100% !important;
  }
}

.info-list dl dd {
  border: none !important;
  padding-top: 0 !important;
}

@media only screen and (max-width: 640px) {
  .info-list dl dd {
    width: 100% !important;
  }
}

.about {
  margin: 0 calc(50% - 50vw);
  position: relative;
  padding: 100px 10px;
}

@media only screen and (max-width: 834px) {
  .about {
    width: 95%;
    margin: 0 auto;
    padding: 80px 0;
  }
}

.about-bg {
  position: absolute;
  background-color: #f6f6f6;
  width: 80%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -1;
}

@media only screen and (max-width: 834px) {
  .about-bg {
    left: -10%;
    width: 100%;
  }
}

@media only screen and (max-width: 834px) {
  .about-title {
    margin-top: 20px;
  }
}

.about-title h2 {
  color: #e60113;
  margin-bottom: 20px;
}

@media only screen and (max-width: 834px) {
  .about-title h2 {
    margin-bottom: 10px;
  }
}

.about-title div {
  font-size: 24px;
  margin-bottom: 60px;
  line-height: 1.8;
}

@media only screen and (max-width: 834px) {
  .about-title div {
    margin-bottom: 40px;
  }
}

.about-inner {
  margin: 0 0 0 auto;
  display: flex;
  justify-content: space-between;
  max-width: 1560px;
  padding: 0 10px;
  width: 100%;
}

@media only screen and (max-width: 834px) {
  .about-inner {
    flex-direction: column-reverse;
  }
}

.about-imgbox {
  width: 55%;
}

@media only screen and (max-width: 834px) {
  .about-imgbox {
    width: 100%;
  }
}

.about-img {
  width: 100%;
}

.about-img img {
  width: 100% !important;
  max-width: 100% !important;
}

.about-textbox {
  width: 45%;
}

@media only screen and (max-width: 834px) {
  .about-textbox {
    width: 100%;
  }
}

.about-text div {
  line-height: 3;
}

.about-btn {
  color: #e60013 !important;
  width: 300px;
  height: 80px;
  display: block;
  text-align: center;
  line-height: 78px;
  position: relative;
  border: 2px solid #c21500;
  border-image: linear-gradient(to right, #e50414 0%, #db6e39 100%);
  border-image-slice: 1;
  margin-top: 50px;
}

@media only screen and (max-width: 834px) {
  .about-btn {
    margin-top: 30px;
  }
}

.about-btn i {
  margin-left: 20px;
}

@media only screen and (max-width: 834px) {
  .insta-blog {
    width: 95%;
    margin: 0 auto;
  }
}

.insta-blog-inner {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .insta-blog-inner {
    flex-direction: column;
  }
}

.insta-blog .insta {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .insta-blog .insta {
    width: 100%;
  }
}

.insta-blog .insta-list {
  margin-top: 60px;
}

.insta-blog .insta .sns_list {
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  border: none;
}

.insta-blog .insta .sns_list > div {
  width: 25%;
  border-bottom: none;
}

@media only screen and (max-width: 1000px) {
  .insta-blog .insta .sns_list > div {
    width: 33.333333%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .insta-blog .insta .sns_list > div {
    width: 25%;
  }
}

.insta-blog .insta .sns_photo {
  width: 100%;
  padding: 5px;
}

.insta-blog .insta .sns_text {
  display: none;
}

.insta-blog .blog {
  width: 50%;
  margin-left: 40px;
}

@media only screen and (max-width: 834px) {
  .insta-blog .blog {
    width: 100%;
    margin-top: 80px;
    margin-left: 0;
  }
}

.insta-blog .blog_list {
  border: none !important;
  margin-top: 60px;
}

.insta-blog .blog_list > div {
  border-bottom: 1px solid #ddd !important;
  padding-bottom: 5px !important;
  margin-bottom: 40px;
}

@media only screen and (max-width: 640px) {
  .insta-blog .blog_list > div {
    margin-bottom: 30px;
  }
}

.insta-blog .blog_text {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  flex-direction: column;
}

.insta-blog .blog_date,
.insta-blog .blog h3 a {
  color: #000 !important;
  font-size: 14px;
}

.insta-blog .blog_date {
  margin-bottom: 15px;
}

.insta-blog .blog_photo,
.insta-blog .blog .detail {
  display: none;
}

.cont_02 {
  padding: 80px 0;
}

.cont_02-flex {
  display: flex;
  justify-content: space-between;
}

.p2-h2 h2 {
  display: flex;
  flex-direction: column-reverse;
  font-size: 34px;
  margin-bottom: 40px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
  position: relative;
  border-bottom: 3px solid #ddd;
  padding-bottom: 30px;
}

@media only screen and (max-width: 640px) {
  .p2-h2 h2 {
    font-size: 28px;
    padding-bottom: 20px;
  }
}

.p2-h2 h2:before {
  position: absolute;
  content: "";
  background-color: #e60113;
  width: 15%;
  height: 3px;
  left: 0;
  bottom: -3px;
}

.p2-h2 h2 span {
  font-size: 16px;
  color: #e60113;
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

@media only screen and (max-width: 640px) {
  .p2-h2 h2 span {
    font-size: 12px;
  }
}

/*コーディネイト*/
.coordinate-text {
  width: 50%;
  margin-right: 80px;
}

@media only screen and (max-width: 834px) {
  .coordinate-text {
    margin-right: 0;
    width: 100%;
    margin-top: 20px;
  }
}

.coordinate-text h2 {
  padding-bottom: 20px;
  line-height: 1.2;
}

@media only screen and (max-width: 834px) {
  .coordinate-text h2 {
    margin-bottom: 25px;
  }
}

.coordinate-text h2 span {
  margin-bottom: 5px;
}

.coordinate-text div {
  line-height: 2;
}

.coordinate-img {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .coordinate-img {
    width: 100%;
  }
}

.coordinate-img img {
  width: 100% !important;
  max-width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .coordinate-flex1 {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 834px) {
  .coordinate-flex2 {
    flex-direction: column;
  }
}

.coordinate-flex2 .coordinate-text {
  margin-left: 80px;
  margin-right: 0px;
}

@media only screen and (max-width: 834px) {
  .coordinate-flex2 .coordinate-text {
    margin-left: 0;
  }
}

.ofi {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.works-box {
  margin: 60px auto;
}

@media only screen and (max-width: 834px) {
  .works-flex {
    flex-direction: column-reverse;
  }
}

.works-flex .img {
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .works-flex .img {
    width: 100%;
  }
}

.works-flex .img img {
  width: 100% !important;
  max-width: 100% !important;
}

.works-list {
  width: 60%;
}

@media only screen and (max-width: 834px) {
  .works-list {
    width: 100%;
  }
}

.works-list dl dt {
  width: 40%;
  font-size: 16px;
  padding: 20px 10px !important;
}

.works-list dl dd {
  font-size: 16px;
  padding: 20px 10px !important;
}

.works-list-img {
  max-height: 300px;
}

.works-list-img img {
  height: 300px !important;
  object-fit: cover;
  width: 100% !important;
}

.works-list-img > div {
  width: 100%;
}

.works-name {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

.works-list-box-wrap {
  display: flex;
  flex-wrap: wrap;
}

.works-list-box {
  width: 33.333333333%;
  padding: 0 10px;
  margin-bottom: 40px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .works-list-box {
    width: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .works-list-box {
    width: 100%;
  }
}

* {
  min-height: 0;
  min-width: 0;
}

.slick-slide {
  outline: none;
}

.works-imgbox .box img {
  height: 300px !important;
  object-fit: cover;
}

.works-imgbox .box div {
  margin-top: 5px;
}

/*会社概要*/
@media only screen and (max-width: 640px) {
  .list dl {
    flex-direction: column !important;
  }
}

.list dl dt {
  padding: 25px 10px !important;
  background: #e60113 !important;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .list dl dt {
    width: 100% !important;
  }
}

.list dl dd {
  padding: 25px 10px !important;
}

@media only screen and (max-width: 640px) {
  .list dl dd {
    width: 100% !important;
  }
}

.list dl dd a {
  color: #333 !important;
}

/*施工例*/
.works-list dl dt {
  background: none !important;
  border: none !important;
}

.works-list dl dd {
  border: none !important;
}

/*お問い合わせ*/
.mail,
.annot {
  display: none;
}

.contact-top-text {
  margin-top: 80px;
}

.mail-form {
  margin-top: 60px;
}

.mail-form dl dt {
  padding: 25px 10px !important;
  align-items: center !important;
}

.mail-form dl dd {
  padding: 25px 10px !important;
}

.mail-form dl .text input {
  height: 250px;
  width: 100% !important;
}

.modal {
  color: #e60113 !important;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}
